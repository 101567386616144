<template>
  <section>
    <app-title color="blue" number="04" title="contact" />
    <img src="../assets/images/triangle.svg" alt="" class="triangle" />
    <div class="title-wrapper">
      <h2>let's make</h2>
      <div class="line-2-wrapper">
        <h2 class="something">something</h2><h2>c</h2><h2>o</h2><h2>o</h2><h2>l</h2>
      </div>
      <h2>together</h2>
    </div>
    <div class="contact-wrapper">
      <div class="contacts">
        <a @click="goToMail" class="mail"><span>melissa.ledoux.france@gmail.com</span></a>
        <br/>
        <p>Reach out at the email above and share info about you and your project. I’ll get back to you very soon.</p>
        <br/>
        <span>Bisous,</span>
        <br/>
        <span>Mélissa</span>
      </div>
    </div>
  </section>
</template>

<script>
import AppTitle from '../components/AppTitle.vue'
export default {
  components: {
    AppTitle
  },
  methods: {
    goToMail () {
      window.location.href = 'mailto:melissa.ledoux.france@gmail.com?subject=Hello Hello'
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/utilities/variables";
.triangle {
  position: absolute;
  top: 576px;
  left: -70px;
  transform: rotate(-15deg);
}

.contact-wrapper {
  position: relative;
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 180px;
}
.contacts {
  margin-right: 276px;
  max-width: 410px;
  width: 100%;

  .mail {
    all: unset;
    color: variables.$green;
    cursor: pointer;
  }

  span {
    font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  }
}
.title-wrapper {
  padding-top: 284px;
  margin-left: 240px;
}

.line-2-wrapper {
  display: flex;

  .something{
    margin-right: 15px;
  }
}

.line-2-wrapper :nth-child(2) {
  color: variables.$yellow;
}

.line-2-wrapper :nth-child(3) {
  color: variables.$blue;
}

.line-2-wrapper :nth-child(4) {
  color: variables.$green;
}

.line-2-wrapper :nth-child(5) {
  color: variables.$purple;
}

h2 {
  font-family: 'Gopher';
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  margin: 0px;
  line-height: 88px;
}

section {
  background: variables.$beige;
  min-height: 100vh;
  position: relative;
}

@media (max-width: variables.$M) {
  .title-wrapper {
    margin-left: 150px;
  }
}

@media (max-width: variables.$S) {
  .title-wrapper {
    margin: 0px 20px;
  }

  .line-2-wrapper {
      .something{
    margin-right: 7px;
  }
  }

  h2 {
    font-family: 'Gopher';
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
  }

  .contacts {
    margin: 0px 20px;
    max-width: 410px;
    width: 100%;

    .mail {
      color: variables.$green;
    }
  }

  .triangle {
    position: absolute;
    top: auto;
    bottom: -36px;
    left: 134px;
    transform: rotate(63deg);
    width: 40%;
  }
}
</style>
