<template>
  <section>
    <app-title color="yellow" number="03" title="playground" />
    <div class="h2-wrapper">
      <h2>a glipse into my film photograpy and illustration wip</h2>
    </div>
    <div class="images-wrapper-mobile">
      <div v-for="(media, index) in imagesUrl"  :key="index" class="media-wrapper">
        <img
          v-if="!isVideo(media)"
          :src="media"
        />
        <iframe
          v-else
          :src="media"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="Pages m&amp;eacute;moire"
          class="video"
        ></iframe>
      </div>

    </div>
  </section>
</template>

<script>
import AppTitle from '../components/AppTitle.vue'
import imagesUrl from '@/assets/playgrounds.json'

export default {
  components: {
    AppTitle
  },
  computed: {
    imageGroups () {
      const listImages = [[]]
      let cpt = 0
      let flagThree = true
      imagesUrl.forEach((image) => {
        if ((flagThree && cpt === 3) || (!flagThree && cpt === 4)) {
          cpt = 0
          flagThree = !flagThree
          listImages.push([])
        }
        listImages[listImages.length - 1].push(image)
        cpt++
      })
      return listImages
    },
    imagesUrl () {
      return imagesUrl
    }
  },
  methods: {
    isVideo (media) {
      return /^https:\/\/player\.vimeo\.com\/video\/\d+\?.*$/.test(media)
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/utilities/variables";

section {
  background: variables.$beige;
  padding-bottom: 1px;
}

.images-wrapper-mobile {
  width: 100%;

  img {
    width: 100%;
  }

  .media-wrapper{
    width: calc(100% - 80px);
    margin-left: 40px;
  }

  .video-wrapper {
    width: 100%;
  }

  .video {
    width: 100%;
    aspect-ratio: 1.51;
  }
}

.h2-wrapper {
  padding: 0px 230px;
  padding-top: 284px;
  margin-bottom: 90px;
}

.image-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 74px;
}

.image-3 {
  width: calc((100% - 2 * 66px) / 3);
}

.image-4 {
  width: calc((100% - 3 * 66px) / 4);
}

h2 {
  font-family: "Gopher";
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  line-height: 88px;
}

@media (max-width: variables.$M) {
  .image-3 {
    width: calc((100% - 2 * 53px) / 3);
  }

  .image-4 {
    width: calc((100% - 3 * 53px) / 4);
  }

  .image-row {
    margin-bottom: 60px;
  }

  .h2-wrapper {
    padding: 0px 150px;
    padding-top: 284px;
  }
}

@media (max-width: variables.$S) {
  section {
    padding: 0px 20px;
  }

  .images-wrapper-mobile {
    display: block;

    .media-wrapper {
      margin-bottom: 20px;
      width: 100%;
      margin-left: 0px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
  }

  .h2-wrapper {
    padding: 0px;
    padding-top: 201px;
    margin-bottom: 60px;
  }
}
</style>
