<template>
  <section>
    <app-title color="purple" number="02" title="about" />
    <img src="../assets/images/hashtag.svg" alt="" class="hashtag" />
    <div class="title">
      <h1 data-color="black">I am a</h1>
      <h1 data-color="blue">french creative</h1>
      <h1 data-color="green">color enthousiast</h1>
      <div class="last-title">
        <h1 data-color="yellow">funny spirit</h1>
        <span>and more :)</span>
      </div>
    </div>
    <div class="mini-hashtag-wrapper">
      <img src="../assets/images/hashtag.svg" alt="" class="mini-hashtag" />
    </div>
    <table class="table-content-1">
      <tr v-for="(line, index) in timeline" :key="index">
          <td><span class="date">{{ line.date }}</span></td>
          <td :colspan="`${index !==5 ? '0' : '2'}`"><span class="position">{{ line.title }}</span></td>
          <td v-if="index !==5"><span class="company-location">{{ line.company }}</span></td>
          <td><span class="company-location">{{ line.location }}</span></td>
      </tr>
    </table>
    <table class="table-content-2">
      <tr v-for="(line, index) in timeline" :key="index">
        <td><span class="date">{{ line.date }}</span></td>
        <td class="cell-wrapper">
          <div><span class="position">{{ line.title }}</span></div>
          <div v-if="index !==5"><span class="company-location">{{ line.company }}</span></div>
          <div><span class="company-location">{{ line.location }}</span></div>
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import AppTitle from '../components/AppTitle.vue'
export default {
  components: {
    AppTitle
  },
  data () {
    return {
      timeline: [
        {
          date: '2022 - \nnow',
          title: 'Midweight designer',
          company: 'Originate',
          location: 'Dublin, Ireland'
        },
        {
          date: '2023',
          title: 'Published in Fedrigoni Calendar',
          company: '',
          location: 'English'
        },
        {
          date: '2020 - \n2022',
          title: 'Junior art director',
          company: 'Dig',
          location: 'Lille, France'
        },
        {
          date: '2020',
          title: 'Master art direction',
          company: 'ECV',
          location: 'Lille, France'
        },
        {
          date: '2017 - \n2020',
          title: 'Digital designer',
          company: 'Dig',
          location: 'Lille, France'
        },
        {
          date: '2019',
          title: 'Graphic designer',
          company: 'Dift',
          location: 'Ghent, Belgium'
        },
        {
          date: '2018',
          title: 'Published in Duotone\n by Sandu Publishing',
          company: '',
          location: 'English'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/utilities/variables";

.cell-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 10px;
}

.table-content-2 {
  display: none;
}

table {
  margin-top: 117px;
  margin-bottom: 140px;
  border-collapse: collapse;
  border-top: 1px solid;
}

tr {
  border-bottom:  1px solid;
}

td {
  padding: 33px 0px;

  .date{
    font-family: 'Gopher';
    font-style: italic;
    font-weight: 500;
    font-size: 24px;
  }

  .position{
    font-family: 'Gopher';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  }

  .company-location{
    font-family: 'Gopher';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
  }
}

.title {
  padding-top: 284px;

  .last-title {
    display: flex;

    span {
      margin: 10px;
    }
  }
}

.hashtag {
  position: absolute;
  right: 200px;
  top: 318px;
}

.mini-hashtag {
  display: none;
  width: 73px;
  margin: 23px 0px;
}

.mini-hashtag-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

h1 {
  font-family: "Gopher";
  font-style: normal;
  font-weight: 700;
  font-size: 88px;
  margin: 0px;
  position: relative;
  z-index: 2;
}

h1[data-color="black"] {
  color: variables.$black;
}

h1[data-color="blue"] {
  color: variables.$blue;
}

h1[data-color="green"] {
  color: variables.$green;
}

h1[data-color="yellow"] {
  color: variables.$yellow;
  white-space: nowrap;
}

section {
  padding: 0px 240px;
  background: variables.$beige;
  display: flex;
  flex-direction: column;
}

@media (max-width: variables.$M) {
  section {
    padding: 0px 150px;
  }

  .hashtag {
    position: absolute;
    right: 113px;
    top: 318px;
  }
}

@media (max-width: variables.$S) {

  .table-content-1 {
    display: none;
  }

  .table-content-2 {
    display: block;
  }

  table {
    margin-top: 0px;
    margin-bottom: 60px;
    width:calc(100vw - 40px);
  }

  td {
    width: 100%;
    padding: 20px 0px;
    .date{
      display:flex;
      font-size: 16px;
      white-space: pre;
    }

    .position{
      font-size: 16px;
      white-space: pre;
    }

    .company-location{
      font-size: 16px;
    }
  }

  .hashtag {
    position: absolute;
    right: 113px;
    top: 318px;
    display: none;
  }

  .mini-hashtag {
    display: block;

  }

  section {
    padding: 0px 20px;
  }

  h1 {
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
  }

  .title .last-title span {
    margin: 0px;
    margin-left: 10px;
  }

  .title {
    padding-top: 201px;
  }

}
</style>
