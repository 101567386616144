<template>
  <div class="h-wrapper">
    <span>{{ number }}</span>
    <div class="title">
      <h2>{{ title }}</h2>
      <div :class="`underline underline-${color}`" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: String,
    title: String,
    color: String
  }
}
</script>
<style lang="scss" scoped>
@use "src/utilities/variables";
.h-wrapper {
  z-index: 2;
  margin-top: 80px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  span {
    margin-right: 5px;
  }

  .title {
    position: relative;

    h2 {
      position: relative;
      z-index: 2;
    }

    .underline {
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 5px;

      &-purple {
        background: variables.$purple;
      }

      &-yellow {
        background: variables.$yellow;
      }

      &-blue {
        background: variables.$blue;
      }
    }
  }
}

@media (max-width: variables.$S) {
  .title-wrapper {
    margin-top: 106px;

    .title .underline {
      position: absolute;
      width: 100%;
      height: 15px;
      bottom: 0px;
    }
  }
}
</style>
