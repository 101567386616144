import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import About from '../pages/About.vue'
import Contact from '../pages/Contact.vue'
import Menu from '../pages/Menu.vue'
import Playground from '../pages/Playground.vue'
import Projects from '../pages/Projects.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/playground',
    name: 'Playground',
    component: Playground
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Projects
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
