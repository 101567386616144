<template>
  <div class="wrapper" ref="titleWrapper">
    <img src="../assets/images/wave.svg" alt="" class="wave">
    <h1>
      <span v-for="(char , index) in titleContent" :key="index">{{char}}</span>
    </h1>
  </div>
</template>

<script>
export default {
  data () {
    return {
      titleContent: 'hi, i’m mélissa, a graphic designer currently based in Dublin'
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
  @use "src/utilities/variables";
  .wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 450px;

    h1 {
      padding: 0px 240px;
      margin:0px;
      z-index: 1;
    }

    img {
      position: absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }
  }

  span{
    font-family: 'Gopher', sans-serif;
    font-weight: 700;
    font-size: 88px;
    line-height: 88px;
    transition: color 5s;
    transition-timing-function: cubic-bezier( 0.88, 0.02, 0.61, -0.06 );
  }

  span:nth-child(4n):hover {
    color: variables.$yellow;
    transition: color 0.1s;
  }

  span:nth-child(4n+1):hover {
    color: variables.$blue;
    transition: color 0.1s;
  }

  span:nth-child(4n+2):hover {
    color: variables.$purple;
    transition: color 0.1s;
  }

  span:nth-child(4n+3):hover {
    color: variables.$green;
    transition: color 0.1s;
  }

  @media (max-width: variables.$M) {
    .wrapper h1 {
      padding: 0px 150px;
    }
  }

  @media (max-width: variables.$S) {
    .wrapper h1 {
      padding: 0px 20px;
    }

    span {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
    }

    img {
      width: 200px;
    }
  }
</style>
