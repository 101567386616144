<template>
  <div class="header-wrapper">
    <header>
      <router-link class="logo" to="/">
        <img src="../assets/images/logo.svg" alt="logo" />
      </router-link>
      <a v-show="showCross" class="option" @click="goBack">
        <cross/>
      </a>
      <router-link v-show="!showCross" class="option" to="/menu">
        <menu-burger/>
      </router-link>
    </header>
  </div>
</template>

<script>
import cross from './svg/cross'
import menuBurger from './svg/menu'

export default {
  props: {
    color: String,
    showCross: Boolean
  },
  methods: {
    goBack () {
      this.$router.back()
    }
  },
  components: {
    menuBurger,
    cross
  }
}
</script>

<style scoped lang="scss">
@use "src/utilities/variables";

img {
  height: 100%;
}

.header-wrapper{
  position: fixed;
  width: 100%;
  z-index: 2;
}

header {
  display: flex;
  justify-content: space-between;
  margin: 0px 244px;
  width: calc(100% - 488px);
  margin-top: 32px;
  position: absolute;

  .logo {
    height: 100px;
  }

  .option {
    height: 32px;
    cursor: pointer;
  }
}

@media (max-width: variables.$M) {
  header {
    display: flex;
    justify-content: space-between;
    margin: 0px 40px;
    width: calc(100% - 80px);
    margin-top: 32px;
  }
}

@media (max-width: variables.$S) {
  header {
    margin: 0px 24px;
    margin-top: 32px;
    width: calc(100% - 48px);
    .logo {
      height: 70px;
    }
  }
}
</style>
