<template>
  <div class="about">
    <div class="links">
      <router-link
        v-for="(link, index) in links"
        :key="index"
        class="link-wrapper"
        :to="link.url"
      >
        <span
          v-for="(letter, index) in link.name"
          :key="index"
          class="title"
          :class="{
            'letter-up': index % 2 !== 0,
            'letter-down': index % 2 === 0,
          }"
        >
          {{ letter }}
        </span>
        <span class="number">{{ `0${index + 1}` }}</span>
      </router-link>
    </div>
    <span class="footer-text">Mélissa Ledoux</span>
  </div>
</template>
<script>
export default {
  data () {
    return {
      links: [
        { name: 'work', url: '/' },
        { name: 'about', url: '/about' },
        { name: 'playground', url: '/playground' },
        { name: 'contact', url: '/contact' }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@use "src/utilities/variables";

.about {
  background: variables.$yellow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.link-wrapper {
  all: unset;
}

.link-wrapper:hover .letter-up {
  transform: translateY(10px);
}

.link-wrapper:hover .letter-down {
  transform: translateY(-10px);
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;

  .link-wrapper {
    display: flex;
    margin: 25px 0px;
    text-decoration: none;
    cursor: pointer;

    .title {
      font-family: "Gopher";
      font-style: normal;
      font-weight: 500;
      font-size: 94px;
      line-height: 88px;
      color: variables.$white;
      transition: transform 0.2s;
    }
  }
}

.footer-text {
  position: absolute;
  bottom: 26px;
  color: variables.$white;
}

@media (max-width: variables.$S) {
  .links .link-wrapper .title {
    font-weight: 500;
    font-size: 50px;
    line-height: 63px;
  }
}
</style>
