<template>
  <div class="home">
      <lottie-animation
        :path=" isMobile ?'./logo-animation-mobile.json' : './animation.json'"
        :loop="false"
        :autoPlay="true"
      />
    <img src="../assets/images/arrow.svg" alt="" class="arrow"/>
    <img src="../assets/images/circle.svg" alt="" class="circle shape" />
    <img src="../assets/images/hashtag.svg" alt="" class="hashtag shape" />
    <img src="../assets/images/triangle.svg" alt="" class="triangle shape" />
    <rainbow-title class="rainbow-title"/>
    <section>
      <div class="subtitle">
        <span class="number">01</span>
        <h2>wanna see my</h2>
        <div class="underline-wrapper">
          <div class="underline"></div>
          <h2>work</h2>
        </div>
        <h2>?</h2>
      </div>
      <ul>
        <li v-for="(project, index) in projectsList" :key="index">
          <router-link :to="`project/${project.slug}`">
            <img :src="project.thumbnail" alt="" class="thumbnail" />
            <span :class="`project-name project-color-${index % 4}`">{{
              project.name
            }}</span>
          </router-link>
          <br />
          <div class="line-wrapper">
            <div class="line" />
            <span class="project-description">{{ project.subtitle }}</span>
          </div>
        </li>
      </ul>
    </section>

    <img src="../assets/images/hashtag.svg" alt="" class="end-hashtag" />
  </div>
</template>

<script>
import projects from '@/assets/projects.json'
import RainbowTitle from '@/components/RainbowTitle.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'Home',
  components: {
    RainbowTitle,
    LottieAnimation
  },
  computed: {
    projectsList () {
      return projects
    },
    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    }
  },
  data () {
    return { size: null }
  },
  mounted () {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.size = 100
    } else {
      this.size = 2000
    }
  }
}
</script>
<style lang="scss" scoped>
@use "src/utilities/variables";

.arrow {
  position: absolute;
  top: min(100vh - 70px,50vw);
  animation: fadin 3s;
}

@keyframes fadin {
  0%   {opacity: 0}
  25%  {opacity: 0}
  50%  {opacity: 0}
  100% {opacity: 1}
}

.home {
  background: variables.$beige;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  .rainbow-title{
    margin: 50px 0px 100px 0px;
  }

  section{
    margin-bottom: 40px;
  }

  .circle {
    position: absolute;
    left: 0px;
    top: 1374px;
  }

  .hashtag {
    position: absolute;
    right: 26px;
    top: 1554px;
  }

  .triangle {
    position: absolute;
    right: 177px;
    bottom: -80px;
  }

  .end-hashtag {
    width: 100px;
    margin-bottom: 50px;
    display: none;
  }

  .subtitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    z-index: 2;
    position: relative;

    .number {
      margin-right: 0.5em;
      margin-top: 0.5em;
    }

    .underline-wrapper {
      position: relative;
      margin-left: 20px;

      h2 {
        position: relative;
        z-index: 2;
      }
      .underline {
        position: absolute;
        top: 50px;
        width: 100%;
        min-height: 40px;
        background: variables.$blue;
      }
    }
  }
  .logo {
    margin: 178px 0px 123px 0px;
  }

  ul {
    margin-top: 120px;
    padding-left: 15px;
    z-index: 1;

    li {
      margin-bottom: 40px;
      list-style-type: none;
      position: relative;

      .thumbnail {
        position: absolute;
        pointer-events: none;
        top: 47%;
        left: 40%;
        width: 400px;
        transform: translateY(-50%);
        visibility: hidden;
        opacity: 0.5;
      }

      a {
        text-decoration: none;

        .project-name {
          font-family: "Gopher", sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 68px;
          line-height: 88px;
          color: transparent;
          z-index: 2;
          position: relative;
        }
      }

      a:hover .thumbnail {
        visibility: visible;
      }

      .line-wrapper {
        display: flex;
        align-items: center;
      }

      .line {
        height: 1px;
        width: 60px;
        background: variables.$black;
        margin-right: 5px;
      }
    }

    .project-color-0 {
      -webkit-text-stroke: 1px variables.$green;
      &:hover {
        color: variables.$green;
      }
    }
    .project-color-1 {
      -webkit-text-stroke: 1px variables.$blue;
      &:hover {
        color: variables.$blue;
      }
    }
    .project-color-2 {
      -webkit-text-stroke: 1px variables.$purple;
      &:hover {
        color: variables.$purple;
      }
    }
    .project-color-3 {
      -webkit-text-stroke: 1px variables.$yellow;
      &:hover {
        color: variables.$yellow;
      }
    }
  }
}

@media (max-width: variables.$S) {

  .arrow {
    position: absolute;
    top: min(100vh - 60px , 200vw);
    animation: fadin 3s;
  }

  .logo {
    margin: 198px 0px 102px 0px;
    width: 200px;
  }

  .home {

    .rainbow-title {
      margin: 10px 0px 10px 0px;
    }

    section {
      margin-bottom: 0px;
    }
    .subtitle {
      justify-content: center;

      .underline-wrapper {
        margin-left: 10px;
        .underline {
          top: 20px;
          min-height: 16px;
        }
      }
    }

    ul {
      padding-inline-start: 0px;
      margin-top: 50px;
      margin-left: 0px;
      padding: 0px 10px;
      li {
        a {
          .project-color-0 {
            color: variables.$green;
          }
          .project-color-1 {
            color: variables.$blue;
          }
          .project-color-2 {
            color: variables.$purple;
          }
          .project-color-3 {
            color: variables.$yellow;
          }
          .project-name {
            font-weight: 700;
            font-size: 34px;
            line-height: 34px;
          }
        }

        .line {
          display: none;
        }
      }
    }

    .shape {
      display: none;
    }

    .end-hashtag {
      display: block;
    }
  }
}
</style>
