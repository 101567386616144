<template>
  <div id="app">
    <app-header :class="{'header-transition' :!isHeaderVisible}"  v-if="isHeaderVisible || HeaderRevealed" :showCross="showCross" />
    <main>
      <router-view :key="$route.path"/>
    </main>
    <app-footer v-if="isFooterVisibe" class="footer"/>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  components: {
    AppFooter: Footer,
    AppHeader: Header
  },
  data () {
    return {
      showCoss: true,
      scrollThreshold: 800,
      HeaderRevealed: false
    }
  },
  computed: {
    isFooterVisibe () {
      switch (this.$route.name) {
        case 'Menu':
          return false
        default:
          return true
      }
    },
    showCross () {
      return this.$route.name === 'Menu'
    },
    isHeaderVisible () {
      switch (this.$route.name) {
        case 'Home':
          return false
        default:
          return true
      }
    }
  },
  methods: {
    handleScroll (event) {
      if (window.scrollY > this.scrollThreshold && this.$route.name === 'Home') {
        this.revealeHeader()
      }
    },
    revealeHeader () {
      this.HeaderRevealed = true
    }

  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    if (window.scrollY > this.scrollThreshold && this.$route.name === 'Home') {
      this.revealeHeader()
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
@use "src/utilities/variables";
@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Montserrat:wght@300;500;600;700;800;900&family=Poppins&display=swap");
@import url("https://use.typekit.net/oeo6qau.css");

#app {
  font-family: "Poppins";
  color: variables.$black;
  position: relative;
  min-height: 100vh;
}

.header-transition{
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer {
  position: relative;
  z-index: 2;
}

html {
  height: 100%;
}

body {
  margin: 0px;
  min-height: 100%;
  position: relative;
}

main {
  min-height: 100vh;
}

span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

h1 {
  font-family: 'Gopher', sans-serif;
  font-weight: 700;
  font-size: 88px;
  line-height: 88px;
}

h2 {
  font-family: 'Gopher', sans-serif;
  font-weight: 400;
  font-size: 74px;
  margin:0px;
}

h4 {
  font-family: "Gopher", sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 43px;
  margin: 0px;
}

  @media (max-width: variables.$S) {
    h1 {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
    }

    h2 {
      font-weight: 400;
      font-size: 30px;
      line-height: 34px;
    }
  }

@font-face {
  font-family: "Gopher";
  src: url("assets/Gopher-Regular.otf") format("otf");
}
</style>
