<template>
  <footer>
    <router-link to="contact" class="title-wrapper"><h4>let’s make something cool together</h4></router-link>
    <div class="links-wrapper">
      <a href="https://www.instagram.com/ladoucepunchline">
        <insta/>
      </a>
      <a href="https://www.behance.net/melissaledoux">
        <behance/>
      </a>
      <a href="https://dribbble.com/melissaledoux">
        <dribbble/>
      </a>
      <a href="https://www.linkedin.com/in/m%C3%A9lissa-ledoux-3a280713a">
        <linkedin/>
      </a>
    </div>
    <div class="bottom-wrapper">
      <span>designed by </span>
      <a href="https://www.melissaledoux.fr/" aria-label="melissa website"><span>melissa</span></a>
      <span> // coded by </span>
      <a href="https://jeanjoulia.fr" aria-label="jean website"><span>jean</span></a>
      <span> // with love</span>
    </div>
  </footer>
</template>

<script>
import insta from '@/components/svg/insta'
import behance from '@/components/svg/behance'
import dribbble from '@/components/svg/dribbble'
import linkedin from '@/components/svg/linkedin'

export default {
  components: {
    insta,
    behance,
    dribbble,
    linkedin
  }
}
</script>

<style lang="scss" scoped>
@use "src/utilities/variables";

footer {
  display: grid;
  background: variables.$black;
  color: variables.$white;
  padding: 50px 240px;
}

.title-wrapper {
  all: unset;
  grid-area: 1 / 1 / 2 / 2;
  text-align: start;
  cursor: pointer;
}

.title-wrapper :hover {
  color: variables.$purple;
}

.links-wrapper {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    margin: 0px 6px;
    height: 32px;
    fill: variables.$white;
  }

  a:nth-child(1):hover{
    fill: variables.$yellow;
  }

  a:nth-child(2):hover{
    fill: variables.$blue;
  }

  a:nth-child(3):hover{
    fill: variables.$purple;
  }

  a:nth-child(4):hover{
    fill: variables.$green;
  }
}
.bottom-wrapper {
  grid-area: 2 / 1 / 3 / 3;
  padding-top: 50px;
  text-align: center;

  a {
    color: variables.$white;
    text-decoration: none;
  }

  a:nth-child(2):hover{
    color: variables.$yellow;
  }

  a:nth-child(4):hover{
    color: variables.$blue;
  }
}

@media (max-width: variables.$M) {
  footer {
    padding: 50px 110px;
  }
}

@media (max-width: variables.$S) {
  footer {
    padding: 28px 0px;
  }

  .title-wrapper {
    grid-area: 2 / 1 / 3 / 2;
    padding-top: 50px;
    padding: 0px 10px;
    text-align: center;

    h4 {
      font-weight: 500;
      font-size: 23px;
    }
  }
  .links-wrapper {
    grid-area: 1 / 1 / 2 / 2;
    justify-content: center;
  }
  .bottom-wrapper {
    grid-area: 3 / 1 / 4 / 2;
  }
}
</style>
