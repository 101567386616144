<template>
  <section class="scoped-project">
    <img v-if="project" :src="project.images[0]" alt="" @load="loaded = true" />
    <template v-if="loaded">
      <div v-if="project" class="description">
        <div class="title-wrapper">
          <h2 :class="`title-color-${colors[projectId % 4]}`">
            {{ project.name }}
          </h2>
          <span>{{ project.date }}</span>
        </div>
        <div class="p-wrapper">
          <p ref="content">
            {{ fetchContent() }}
          </p>
          <ul class="index">
            <li v-for="(line, index) in project.index" :key="index">
              {{ line }}
            </li>
          </ul>
        </div>
      </div>
      <div v-for="(content, index) in images" :key="index">
        <img v-if="isImage(content)" alt="" :src="content" />
        <div v-else class="video-player">
          <iframe
            :src="content.url"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            title="Pages m&amp;eacute;moire"
            style="width:100%;height:100%;"
          />
        </div>
      </div>
      <div class="bottom-navigation">
        <router-link class="navigation-wrapper navigation-left" to="/">
          <div class="navigation-top-line">
            <span>go back</span>
            <div class="line line-left" />
          </div>
          <span class="big-text">home</span>
        </router-link>
        <router-link
          class="navigation-wrapper"
          :to="{ name: 'Project', params: { id: nextProject } }"
        >
          <div class="navigation-top-line">
            <div class="line line-right" />
            <span>let's see</span>
          </div>
          <span class="big-text">another</span>
        </router-link>
      </div>
    </template>
  </section>
</template>

<script>
import projects from '@/assets/projects.json'
import Vue from 'vue'

export default {
  mounted () {
    const index = projects.findIndex(
      (project) => project.slug === this.$route.params.id
    )
    this.project = projects[index]
    this.projectId = index
  },
  data () {
    return {
      project: null,
      projectId: null,
      loaded: false,
      colors: ['green', 'blue', 'purple', 'yellow']
    }
  },
  computed: {
    images () {
      if (!this.project) return []
      return this.project.images.slice(1)
    },
    nextProject () {
      if (!this.project) return ''
      const index = projects.findIndex((pro) => this.project.slug === pro.slug)
      return projects[(index + 1) % projects.length].slug
    }
  },
  methods: {
    async fetchContent () {
      await Vue.nextTick()
      this.$refs.content.innerHTML = this.project.content
    },
    isImage (content) {
      return typeof content === 'string'
    }
  }
}
</script>

<style lang="scss">
@use "src/utilities/variables";
img {
  pointer-events: none;
}

.scoped-project {
  background: variables.$beige;
  min-height: 100vh;

  .navigation-wrapper {
    cursor: pointer;
    text-decoration: none;
  }

  .navigation-wrapper:hover .big-text {
    color: variables.$green;
  }

  .navigation-top-line {
    display: flex;
    align-items: center;
    span {
      color: variables.$black;
    }
  }

  .bottom-navigation {
    display: flex;
    justify-content: space-between;
    margin: 0px 240px;
    padding: 50px 0px;
  }

  .big-text {
    font-family: "Gopher";
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
    line-height: 86px;
    -webkit-text-stroke: 1px variables.$green;
    color: transparent;
  }

  .line {
    width: 60px;
    height: 0px;
    border-bottom: 1px solid variables.$black;

    &-left {
      margin-left: 5px;
    }

    &-right {
      margin-right: 5px;
    }
  }

  .description {
    margin-top: 60px;
  }

  .link {
    color: variables.$purple;
    text-decoration: none;
  }

  .link:hover {
    color: variables.$green;
  }

  .video-player{
    width: 100%;
    aspect-ratio: 1.951;
    margin:4vw 0px;

    iframe {
      height: 100%;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-left: 427px;

    span {
      margin-top: 5px;
    }
  }

  .p-wrapper {
    padding: 0px 556px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 60px;
  }

  ul {
    list-style-type: none;
    border-left: 1px solid;
    line-height: 40px;
    padding-left: 20px;
  }

  p {
    max-width: 440px;
    width: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }

  h2 {
    font-family: "Gopher";
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
    line-height: 86px;
  }

  .title-color {
    &-green {
      color: variables.$green;
    }
    &-blue {
      color: variables.$blue;
    }
    &-purple {
      color: variables.$purple;
    }
    &-yellow {
      color: variables.$yellow;
    }
  }

  img {
    width: 100%;
  }

  @media (max-width: 1680px) {
    .p-wrapper {
      padding: 0px 280px;
    }

    .title-wrapper {
      margin-left: 150px;
    }
  }

  @media (max-width: 1120px) {
    ul {
      display: none;
    }
    .p-wrapper {
      padding: 0px;
      padding-left: 280px;
    }
  }

  @media (max-width: variables.$M) {
    .bottom-navigation {
      margin: 0px 110px;
    }
  }

  @media (max-width: variables.$S) {
    ul {
      display: none;
    }
    .p-wrapper {
      padding: 0px 20px;
    }

    p {
      max-width: 100%;
    }

    h2 {
      font-weight: 700;
      font-size: 34px;
      line-height: 34px;
    }

    .title-wrapper {
      margin: 0px 20px;
      align-items: center;

      span {
        display: none;
      }
    }

    .navigation-left {
      display: none;
    }

    .bottom-navigation {
      display: flex;
      justify-content: center;
      padding: 40px 0px;
    }
  }
}
</style>
