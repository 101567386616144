// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export function setupFirebase () {
  const firebaseConfig = {
    apiKey: 'AIzaSyAtI-Qbsalbmb24__AYQywObH_-l6l3kg4',
    authDomain: 'melissa-website-d62d6.firebaseapp.com',
    projectId: 'melissa-website-d62d6',
    storageBucket: 'melissa-website-d62d6.appspot.com',
    messagingSenderId: '996246713203',
    appId: '1:996246713203:web:65d2fcc292465ee3ed8b06',
    measurementId: 'G-KZFV6PS9FE'
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  getAnalytics(app)
}
